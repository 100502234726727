const config = {
	
	// for localhost
	// baseurl : 'http://localhost:3000/',
	// apibaseurl : 'https://ewayswork.com/egiftportal/api/',
	// apibaseurl : 'http://1800gportal.com/api/',
	// perPageData: 20, //no trialing slash here
	
    // for live
	baseurl : 'https://1800gportal.com/',
	apibaseurl : 'https://1800gportal.com/api/',
	perPageData: 20
	// perPageData: 20, //no trialing slash here
};

export default config;