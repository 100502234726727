import React from 'react';
import { Link } from "react-router-dom";
export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="w-100 clearfix ewp-footer">
                    <div className="inner-footer">

                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
                            Copyright © 2021. All rights reserved.
                        </span>


                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">

                            <div className="image-container">
                                <div className="testtt">
                                    <img src="../e_logo.png" />
                                </div>
                                <div className="image-caption caption-4">

                                    <p>Eways Soft Solution</p>
                                </div>
                            </div>

                        </span>

                    </div>
                </div>
            </footer>
        </>
    )
}
