import React, { Component, lazy, Suspense, useState,useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from './Auth/Login'
import loader from './loader/loading.gif'
const Role = lazy(() => import('./role/Role'));
const Users = lazy(() => import('./users/Users'));
const Edituser = lazy(() => import('./users/Edituser'));
const CreateUsers = lazy(() => import('./users/CreateUsers'));
const CreateRole = lazy(() => import('./role/CreateRole'));
const Editrole = lazy(() => import('./role/Editrole'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const ViewEditConfig = lazy(() => import('./vendors/VEconfig'));

const Vendors = lazy(() => import('./vendors/Vendors'));
const Editvendor = lazy(() => import('./vendors/Editvendor'));
const CreateVendors = lazy(() => import('./vendors/Createvendors'));

const Orders = lazy(() => import('./Orders/UnprintedOrderList'));
const Createchalan = lazy(() => import('./Orders/Createchalan'));
const ImportOrder = lazy(() => import('./Orders/Importorder'));

const PrintedOrderList = lazy(() => import('./Orders/PrintedOrderList'));

const userprofile = lazy(() => import('./UserProfile/Profile'));

const Product = lazy(() => import('./product/Product'));
const Createproduct = lazy(() => import('./product/Createproduct'));
const Editproduct = lazy(() => import('./product/Editproduct'));
const importProduct = lazy(() => import('./product/importProduct'));
const ProductDelete = lazy(() => import('./product/ProductDelete'));

const authGuard = (Component) => () => {
  return localStorage.getItem("user") ? (
    <Component />
  ) : (
    <Redirect to="/userlogin" />
  );
};

const Routes = (props) => (
  <Router {...props}  basename={'/'} >
  
	<Suspense fallback={<div> <span className="ew-loader align-items-center justify-content-center d-flex w-100 h-100 min-vh-100">
        <img
          src={loader} className="loader" alt="loader"
        />
      </span></div>}>
		<Switch>
			<Route path="/userlogin">
				<Login />
			</Route>
			
			<Route path="/dashboard" render={authGuard(Dashboard)}></Route>
			<Route exact path="/">
				<Redirect to="/dashboard" />
			</Route>
			
			<Route path="/users" render={authGuard(Users)}></Route>
			<Route exact path="/">
				<Redirect to="/users" />
			</Route>
			
			<Route path="/createuser" render={authGuard(CreateUsers)}></Route>
			<Route exact path="/">
				<Redirect to="/createuser" />
			</Route>
			
			<Route path="/edituser/:id" render={authGuard(Edituser)}></Route>
			<Route exact path="/">
				<Redirect to="/edituser/:id" />
			</Route>
			
			<Route path="/role" render={authGuard(Role)}></Route>
			<Route exact path="/">
				<Redirect to="/role" />
			</Route>

			<Route path="/createrole" render={authGuard(CreateRole)}></Route>
			<Route exact path="/">
				<Redirect to="/createrole" />
			</Route>
			
			<Route path="/editrole/:id" render={authGuard(Editrole)}></Route>
			<Route exact path="/">
				<Redirect to="/editrole/:id" />
			</Route>

			<Route path="/importProduct" render={authGuard(importProduct)}></Route>
			<Route exact path="/">
				<Redirect to="/importProduct" />
			</Route>
			
			<Route path="/productdelete" render={authGuard(ProductDelete)}></Route>
			<Route exact path="/">
				<Redirect to="/productdelete" />
			</Route>

			<Route path="/vendors" render={authGuard(Vendors)}></Route>
			<Route exact path="/">
				<Redirect to="/vendors" />
			</Route>
			
			<Route path="/editvendor/:id" render={authGuard(Editvendor)}></Route>
			<Route exact path="/">
				<Redirect to="/editvendor/:id" />
			</Route>
			<Route path="/createvendors" render={authGuard(CreateVendors)}></Route>
			<Route exact path="/">
				<Redirect to="/createvendors" />
			</Route>
			<Route path="/veConfig/:id" render={authGuard(ViewEditConfig)}></Route>
			<Route exact path="/">
				<Redirect to="/veConfig" />
			</Route>

			<Route path="/UnprintedOrderlist" render={authGuard(Orders)}></Route>
			<Route exact path="/">
				<Redirect to="/UnprintedOrderlist" />
			</Route>
			<Route path="/importorder" render={authGuard(ImportOrder)}></Route>
			<Route exact path="/">
				<Redirect to="/importorder" />
			</Route>

			<Route path="/PrintedOrderList" render={authGuard(PrintedOrderList)}></Route>
			<Route exact path="/">
				<Redirect to="/PrintedOrderList" />
			</Route>

			<Route path="/createchalan" render={authGuard(Createchalan)}></Route>
			<Route exact path="/">
				<Redirect to="/createchalan" />
			</Route>
			
			


			<Route path="/userProfile" render={authGuard(userprofile)}></Route>
			<Route exact path="/">
				<Redirect to="/userProfile" />
			</Route>
			
			
			<Route path="/product" render={authGuard(Product)}></Route>
			<Route exact path="/">
				<Redirect to="/product" />
			</Route>
			<Route path="/createproduct" render={authGuard(Createproduct)}></Route>
			<Route exact path="/">
				<Redirect to="/createproduct" />
				</Route>
				<Route path="/editproduct/:id" render={authGuard(Editproduct)}></Route>
			<Route exact path="/">
				<Redirect to="/editproduct/:id" />
			</Route>
	
		</Switch>
	</Suspense>
  </Router>
);

export default Routes;