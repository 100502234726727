import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {FaUserAlt , FaEnvelope, FaKey } from "react-icons/fa";
import './login.css';
import config from '../config';
import Footer from '../footer/Footer'

export default function Login() {
	const [userName, setuserName] = useState('');
	const [userPassword, setuserPassword] = useState('');
	const [usernameErr, setusernameErr] = useState(0);
	const [userPassworderr, setuserPassworderr] = useState(0);
	const [userNotfounderr, setuserNotfounderr] = useState(0);
	const [loginerr, setloginerr] = useState(0);
	
	function getusername(e){
		setusernameErr(0)
		setuserPassworderr(0)
		setuserNotfounderr(0)
		setloginerr(0)
		 var uname = e.target.value
		 setuserName(uname)
	}
	
	function getpass(e){
		setuserPassworderr(0)
		setusernameErr(0)
		setuserNotfounderr(0)
		setloginerr(0)
		 var pass  = e.target.value
		 setuserPassword(pass)
	}
	
	function submitForm(e){
		e.preventDefault();
		setusernameErr(0)
		setuserPassworderr(0)
		setuserNotfounderr(0)
		setloginerr(0)
		var username = userName
		var pass = userPassword
		if(!username){
			setusernameErr(1)
		}else if(!pass){
			setuserPassworderr(1)
		}else{
			var data = {
				'username':username,
				'password':pass
			}
			fetch(config.apibaseurl+'login/login.php',{
			   method: 'POST',
			   headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify(data)
			}).then((res) => res.json())
			  .then((result) => {
				if (result.CODE == '200') {
					// console.log(result)
					window.localStorage.setItem('user',JSON.stringify(result))
					//window.localStorage.setItem('user',result)
					window.location.href = config.baseurl+'dashboard'
				}else if(result.CODE == '404'){
					setuserNotfounderr(1)
				}else{
					setloginerr(1)
				}
			  })
		}
	}
	


    return (
<>
		<nav className="navbar bg-bck ewp-log">
                <div className="container">
                    <Link to="/" className="navbar-brand ewp-navbar-brand"><img src="../logo3.png" className='portal_img' /></Link>
                </div>
        </nav>

		<div class="form-wrap">
            <div className="row-form">
                <span className="user ew-user"><FaUserAlt /></span>
				<div className="welcome-text"><h2>WELCOME</h2></div>
				<div className = "err-container">
				{ (usernameErr) ? <> <p className ="errp">Username is required</p> </>: '' }
				{ (userPassworderr) ? <> <p className ="errp">Password is required</p> </> : '' }
				{ (userNotfounderr) ? <> <p className ="errp">Invalid Username Or Password</p> </> : '' }
				{ (loginerr) ? <> <p className ="errp">Oops something went wrong</p> </> : '' }
				</div>

                <div className="form-style">
                    <form>

                        <div className="form-group form-log_in">
							<div className="ew-svg ew-left">
                            	<FaEnvelope />
                            </div>    
                            	<input type="email" name = "username"  onChange={(e) => getusername(e)}  value = {userName} placeholder="E-mail Address" className="form-control form-ok" required/>
							
                        </div>
                        
                        <div className="form-group form-log_in">
                            <div className="ew-svg">
                                <FaKey/>
                        </div>    
                            	<input type="password" placeholder="Password" name = "password" onChange={(e) => getpass(e)}
								className="form-control form-ok"  required  value = {userPassword} />
							
						</div>
                        
                        <div className="d-flex align-items-center justify-content-between submit-form">
                            <div className="d-flex align-items-center log-remember"><input name="" type="checkbox" value="" /> <span className="pl-2 ">Remember Me</span></div>
                        </div>

                        <div className="pb-3 btn-imp">
							<button type="submit" className="btn w-100 ew-primary-col ew-primary-col-text mt-2 btn-form btn-log" onClick ={(e) => submitForm(e)} >Sign In</button>
                        </div>

                    </form>

                </div>

            </div>


        </div>
		<Footer/>
	</>
    )
}



