import React from 'react';
import Routes from './Routes';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import './ButtonComponent.css';




function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
